import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './playTextButton.module.css';
import { Button } from '@mui/material';

interface PlayerI {
	text: string;
	voice: string;
	disabled: boolean;
}

const PlayTextButton: FC<PlayerI> = ({ text, voice, disabled }) => {
	const [vocals, setVocals] = useState<string>();
	const [play, setPlay] = useState<boolean>(false);
	const myAudio = useRef(null);

	const handleClick = async () => {
		if (document.activeElement instanceof HTMLElement) {
			document.activeElement.blur();
		}
		if (!vocals)
			setVocals(
				`https://speech2.etcd.kloud.one/tts?text=${text}&speaker=${voice}&key=getter&format_audio=MP3`
			);
		play ? setPlay(false) : setPlay(true);
		if (myAudio.current !== null) {
			// @ts-ignore
			play ? myAudio!.current.pause() : myAudio!.current.load();
		}		
	};

	useEffect(() => {
		// @ts-ignore
		myAudio!.current.addEventListener('ended', () => {
			setPlay(false);
			setVocals('');
		});
		// @ts-ignore
		myAudio!.current.addEventListener('pause', () => {
			setPlay(false);
			setVocals('');
		});
	}, []);

	return (
		<>
			{
				<Button
					// startIcon={play ? <Stop /> : <PlayArrow />}
					disabled={disabled}
					className={styles.button}
					onClick={handleClick}
				>
					{play ? 'Остановить' : 'Прослушать'}
				</Button>
			}
			<audio ref={myAudio} autoPlay className={styles.audio} src={vocals} />
		</>
	);
};

export default PlayTextButton;

import React, { FC, useState } from 'react';
import styles from './BalanceModal.module.css';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { LINK_BUY } from '../../utils/const';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import CencelHeaderButton from '../Buttons/cencelHeaderButton/CencelHeaderButton';
import CustomInput from '../CustomInput/CustomInput';
import CustomButton from '../Buttons/customButton/CustomButton';
import { activateLicence } from '../../service/UsersService';
import { fetchProfile } from '../../service/ProfileServiceThunk';
import ReactDOM from 'react-dom';
import { setBitrixModal } from '../../store/reducers/ModalSlice';

type BalanceModalProps = {
	open: boolean;
	setClose: (value: boolean) => void;
};

const BalanceModal: FC<BalanceModalProps> = ({ open, setClose }) => {
	const { profile } = useAppSelector((state) => state.profileReducer);
	const [licenseValue, setLicenseValue] = useState<string>('');
	const [licenseValid, setLicenseValid] = useState<
		'root' | 'disabled' | 'error' | 'success'
	>('root');
	const [count, setCount] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [forceLicenseInputShow, setForceLicenseInputShow] =
		useState<boolean>(true);
	const [licenseInputShow, setLicenseInputShow] = useState<boolean>(false);
	const [errorMessage, setErrorMesasge] = useState<string>('');
	const [disabledActivateButton, setDisabledActivateButton] =
		useState<boolean>(true);

	const dispatch = useAppDispatch();

	const closeModal = () => {
		setClose(false);
		setForceLicenseInputShow(true);
	};

	const checkValidate = (value: string) => {
		return /^[A-Z0-9]{5}(-[A-Z0-9]{5}){6}$/.test(value);
	};

	const changeInput = (value: string) => {
		setLicenseValue(value);
		setDisabledActivateButton(true);
		if (value.length && checkValidate(value)) {
			setLicenseValid('success');
			setErrorMesasge('');
			setDisabledActivateButton(false);
		} else if (!value.length) {
			setLicenseValid('error');
			setErrorMesasge('Введите лицензионный ключ');
		} else {
			setLicenseValid('error');
			setErrorMesasge('Некорректный ключ');
		}
	};

	const toggleForceLicenseInputShow = () => {
		setForceLicenseInputShow(!forceLicenseInputShow);
	};

	const toggleLicenseInputShow = () => {
		setLicenseInputShow(!licenseInputShow);
	};

	React.useEffect(() => {
		setLicenseInputShow(true);
	}, []);

	const onActivateLicence = async () => {
		try {
			setIsLoading(true);
			const response = await activateLicence(licenseValue, 'KLR');
			if (response.error) {
				const responseInfo = response.error.response.data.message;
				setErrorMesasge(responseInfo)
				setLicenseValid('error');
				// if (responseInfo.includes('is invalid')) {
				// 	setErrorMesasge('Ключ недействителен');
				// 	setLicenseValid('error');
				// } else if (responseInfo.includes('already activated')) {
				// 	setErrorMesasge('Лицензионный ключ уже активирован');
				// 	setLicenseValid('error');
				// } else if (
				// 	responseInfo.includes('Лицензия для данного приложения не применима')
				// ) {
				// 	setErrorMesasge('Лицензия для данного приложения не применима');
				// 	setLicenseValid('error');
				// } else {
				// 	setErrorMesasge('Произошла ошибка при активации ключа');
				// 	setLicenseValid('error');
				// }
				setDisabledActivateButton(true);
			} else {
				const { balance } = response.data.data;
				dispatch(fetchProfile());
				setLicenseInputShow(false);
				setCount(balance);
			}
		} finally {
			setIsLoading(false);
		}
	};

	const showModal = () => {
		dispatch(
			setBitrixModal({
				show: true,
			})
		);

		setTimeout(() => {
			const scriptWrapper = document.createElement('script');
			scriptWrapper.setAttribute('data-b24-form', 'inline/33/f6zo77');
			scriptWrapper.setAttribute('data-skip-moving', 'true');
			const script = document.createElement('script');
			script.src =
				'https://crm.digtlab.ru/upload/crm/form/loader_33_f6zo77.js' +
				'?' +
				((Date.now() / 180000) | 0);
			script.async = true;
			scriptWrapper.appendChild(script);
			const bitrix = document.getElementById('bitrix');
			bitrix?.appendChild(scriptWrapper);
		}, 100);
	};

	return ReactDOM.createPortal(
		<Modal
			open={open}
			onClose={closeModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<div className={styles.balance_card}>
				<div className={styles.balance_card__body}>
					{profile?.balance_shop === 0 && forceLicenseInputShow ? (
						<>
							<h2>Необходимо пополнить счет</h2>
							<div className={styles.balance_card__form}>
								<p className={styles.balance_card__form_count_info}>
									Ваш баланс составляет <span>{profile?.balance_shop}</span>{' '}
									звонков.
								</p>
								<button
									className={styles.balance_card__footer_link_activate}
									onClick={toggleForceLicenseInputShow}
								>
									<span className={styles.balance_card__footer_link}>
										Введите лицензионный ключ
									</span>
								</button>
								<a href={LINK_BUY} target="_blank" rel="noreferrer">
									<span className={styles.balance_card__footer_link}>
										Купите ключ в онлайн-магазине Kloud.one
									</span>
								</a>

								<div className={styles.balance_card__form_options}>
									<button className={styles.balance_card__footer_link} />
									<CencelHeaderButton
										text={'Закрыть'}
										disabled={isLoading}
										className={styles.close_balance_success}
										onClick={closeModal}
									/>
								</div>
							</div>
						</>
					) : (
						<>
							<h2>Пополнение счета "Обработка заказов"</h2>
							<div className={styles.balance_card__form}>
								{licenseInputShow ? (
									<>
										<span className={styles.balance_card__form_label}>
											Введите лицензионный ключ:
										</span>
										<CustomInput
											mode={licenseValid}
											placeholder="XXXX-XXXX-XXXX"
											value={licenseValue}
											changeInput={changeInput}
											errorMessage={errorMessage}
										/>
										<p className={styles.balance_card__form_label_key}>
											Нет ключа?
										</p>
										<a href={LINK_BUY} target="_blank" rel="noreferrer">
											<span className={styles.balance_card__footer_link}>
												Купите в онлайн-магазине Kloud.one
											</span>
										</a>
										<a target="_blank" rel="noreferrer" onClick={showModal}>
											<span className={styles.balance_card__footer_link}>
												Запросите триальную лицензию
											</span>
										</a>
										<div className={styles.balance_card__form_buttons}>
											<div
												className={styles.balance_card__form_buttons_container}
											>
												<CencelHeaderButton
													className={styles.close_balance}
													disabled={isLoading}
													text={'Отмена'}
													onClick={closeModal}
												/>
												<CustomButton
													className={styles.activate_balance}
													disabled={isLoading}
													text={'Активировать'}
													onClick={onActivateLicence}
													disabledActivateButton={disabledActivateButton}
												/>
											</div>
										</div>
									</>
								) : (
									<div className={styles.balance_card__form}>
										<p className={styles.balance_card__form_success}>
											Ключ активирован!
										</p>
										<p className={styles.balance_card__form_count_info}>
											На ваш баланс зачислено{' '}
											<span className={styles.count}>{count}</span> звонков.
										</p>
										<div className={styles.balance_card__form_options}>
											<button
												className={styles.balance_card__footer_link}
												onClick={toggleLicenseInputShow}
											>
												<span className={styles.balance_card__footer_link}>
													Активировать ещё
												</span>
											</button>
											<CencelHeaderButton
												text={'Закрыть'}
												disabled={isLoading}
												className={styles.close_balance_success}
												onClick={closeModal}
											/>
										</div>
									</div>
								)}
							</div>
						</>
					)}
				</div>
				<CloseIcon className={styles.close_button} onClick={closeModal} />
			</div>
		</Modal>,
		document.getElementById('portal')!
	);
};

export default BalanceModal;
